:root {
  --terminal-font: #6a8a1d;
}

body {
  margin: 0;
  font-family: OperatorMono, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000;
}

@font-face {
  font-family: OperatorMono;
  src: url('./assets/fonts/OperatorMono-Light.otf') format('opentype');
}

@font-face {
  font-family: OperatorMono;
  font-weight: bold;
  src: url('./assets/fonts/OperatorMono-Bold.otf') format('opentype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  color: white;
}

@-webkit-keyframes cursor-blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes cursor-blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.four-oh-four {
  position: relative;
  top: 0;
  left: 0;
  min-height: 100vh;
  min-width: 100vw;
  z-index: 2;
  background-color: black;
  transition: opacity 300ms ease-out;
  background-position: center center;
  background-repeat: no-repeat;
}
.four-oh-four .dJAX_internal {
  opacity: 0;
}
.here {
  position: fixed;
  left: 0;
  opacity: 0;
  background-color: black;
  width: 100%;
}

.terminal {
  position: relative;
  padding: 1rem;
}

.terminal .text {
  color: var(--terminal-font);
  display: block;
  font-family: OperatorMono, 'Consolas', 'monaco', monospace;
  font-weight: bold;
  font-size: 1.5em;
  white-space: pre-wrap;
  line-height: 1;
  margin-bottom: 0.75em;
}

.terminal .prompt {
  color: var(--terminal-font);
  display: block;
  font-family: OperatorMono, 'Consolas', 'monaco', monospace;
  font-weight: bold;
  font-size: 0.9em;
  white-space: pre-wrap;
  line-height: 1;
  margin-bottom: 0.75em;
  padding: 0.1em;
}

.terminal .prompt-header {
  color: #efefa9;
  display: block;
  font-family: OperatorMono, 'Consolas', 'monaco', monospace;
  font-size: 0.8vw;
  white-space: pre-wrap;
  line-height: 0.6;
  margin-bottom: 0.75em;
}

.terminal .prompt:before {
  content: 'guest@donbasta.dev ';
  display: inline-block;
  font-weight: bolder;
  background-color: #6c99bb;
  color: #06290b;
  padding: 0.3em;
  border-radius: 0.5em;
}

.terminal .new-output {
  display: inline-block;
}

.terminal .new-output:after {
  display: inline-block;
  vertical-align: -0.15em;
  width: 0.75em;
  height: 1em;
  margin-left: 5px;
  background: var(--terminal-font);
  -webkit-animation: cursor-blink 1.25s steps(1) infinite;
  -moz-animation: cursor-blink 1.25s steps(1) infinite;
  animation: cursor-blink 1.25s steps(1) infinite;
  content: '';
}

.result {
  color: var(--terminal-font);
  display: block;
  width: 120vh;
  font-family: OperatorMono, 'Consolas', 'monaco', monospace;
  font-size: 0.9em;
  white-space: pre-wrap;
  line-height: 1;
  margin-bottom: 0.75em;
}

a {
  color: white;
  text-decoration: none;
  display: inline-block;
  padding: 25px;
}

a:hover {
  text-decoration: underline;
}

img {
  padding: 10px;
  vertical-align: middle;
}

.header-box {
  margin-top: 20px;
  border: thin solid var(--terminal-font);
  padding: 20px;
  width: fit-content;
}
